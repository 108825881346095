<template>
  <v-footer style="background-color: #D9B436" class="mt-16">
    <v-container>
      <v-row>
        <v-col cols="12" class="text-center">
          <span>Get connected with us on social networks!</span>
        </v-col>
      </v-row>
      <v-row class="d-flex flex-wrap"> <!-- Utilisez d-flex flex-wrap pour les colonnes côte à côte et responsives -->
        <v-col cols="12" sm="6" md="3" lg="3"> <!-- Utilisez les classes sm, md, lg pour spécifier le nombre de colonnes pour différents écrans -->
          <h6 class="text-uppercase fw-bold mb-4" style="color: white">Products</h6>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec mattis bibendum elit, sit amet dapibus mi ornare at.
            Aliquam erat volutpat. Vestibulum eget nisi auctor, faucibus felis ut, efficitur ipsum.
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3"> <!-- Utilisez les classes sm, md, lg pour spécifier le nombre de colonnes pour différents écrans -->
          <h6 class="text-uppercase fw-bold mb-4" style="color: white">Useful links</h6>
          <p>
            <a href="#!" class="text-reset">Pricing</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Settings</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Orders</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Help</a>
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3"> <!-- Utilisez les classes sm, md, lg pour spécifier le nombre de colonnes pour différents écrans -->
          <h6 class="text-uppercase fw-bold mb-4" style="color: white">Useful links</h6>
          <p>
            <a href="#!" class="text-reset">Pricing</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Settings</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Orders</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Help</a>
          </p>
        </v-col>
        <v-col cols="12" sm="6" md="3" lg="3"> <!-- Utilisez les classes sm, md, lg pour spécifier le nombre de colonnes pour différents écrans -->
          <h6 class="text-uppercase fw-bold mb-4" style="color: white">Useful links</h6>
          <p>
            <a href="#!" class="text-reset">Pricing</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Settings</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Orders</a>
          </p>
          <p>
            <a href="#!" class="text-reset">Help</a>
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="text-center">
          © 2023 Copyright:
          <a class="text-reset fw-bold">R.I.C.H</a>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
</style>