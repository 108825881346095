import Api from '@/services/api'

export default {

    //-----------------------------------------------------//
    //                    Get
    //-----------------------------------------------------//

    getBackgroundImages() {
        return Api().get('/getBackgroundImages')
    },

    getSpecificBackgroundImage(page) {
        return Api().get('/getSpecificBackgroundImage', {
            params: {
                page: page
            }
        });
    },

    //-----------------------------------------------------//
    //                    Post
    //-----------------------------------------------------//

    uploadImage(data) {
        return Api().post('/uploadImage', data);
    },

    updateImage(data) {
        return Api().put('/updateImage', data);
    }
}