<template>
    <div>
    </div>
  </template>
  
  <script>
  import {getAuth, signOut} from "firebase/auth"

  export default {
    name: 'AutoLogout',
    data() {
      return {
        events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
        logoutTimer: null,
      };
    },
    mounted() {
      this.events.forEach((event) => {
        window.addEventListener(event, this.resetTimer);
      });
      this.setLogoutTimer();
    },
    unmounted() {
    this.removeEventListeners();
  },
    methods: {
      setLogoutTimer() {
        this.logoutTimer = setTimeout(() => {
          // Perform logout logic here
          this.logoutAndDestroyListeners()
        }, 15 * 60 * 1000); // 15 minutes : 15 * 60 * 1000
      },
      resetTimer() {
        clearTimeout(this.logoutTimer);
        this.setLogoutTimer();
      },
      async logout(){
      try {
        const auth = getAuth()
       signOut(auth).then(async ()=>{
          await this.$store.dispatch('setToken', null)
          await this.$store.dispatch('setUser', null)
          window.location.href = '/'
        })
      } catch (error) {
        console.log(error)
      }
    },
      logoutAndDestroyListeners() {
      // Perform logout logic here
      this.logout();

      // Destroy event listeners
      this.removeEventListeners();
    },

    removeEventListeners() {
      this.events.forEach((event) => {
        window.removeEventListener(event, this.resetTimer);
      });
    },
  }
}
  </script>
  
  <style scoped></style>
  