<template>
  <v-app>
    <v-parallax
        :src="backgroundImage.imageUrl"
        class="mt-n16"
        height="600"
    >

      <div class="d-flex flex-column fill-height justify-center align-center text-white">
        <h1 class="text-h3 font-weight-thin px-16 mx-16 mb-8">
          Dirigez votre argent, et menez la vie que vous meritez
        </h1>

          <!--<v-container class="w-25 text-center mt-10">
            <v-row justify="center">
              <v-col>
                <v-btn rounded="lg" color="#D7BB4C" size="x-large" @click="$router.push('/articles')">Articles</v-btn>
              </v-col>

              <v-col>
                <v-btn rounded="lg" size="x-large"  @click="$router.push('/boutique')">Boutique</v-btn>
              </v-col>
            </v-row>
          </v-container>-->

      </div>

    </v-parallax>
    <h1 class="mt-16">Bienvenue sur R.I.C.H
    </h1>
    <v-row class="mt-10 mx-16">
      <v-col cols="6" class="d-flex align-center justify-center">
        <h4 class="text-center" v-html="`De quoi avez-vous réellement besoin ? <br>Pour être en sécurité, pour être LIBRE de vivre votre vie tel que vous l'entendez,<br>Pour être HEUREUX, tout simplement...<br><br>Si nous ne connaissons pas nos besoins, l'argent ne fera pas toujours notre bonheur.<br>Dès que nous les connaissons, l'argent permet de répondre à de nombreux besoins (acquérir un bien immobilier, s'offrir des vacances, protéger sa famille du besoin, obtenir des rentes...).<br><br>Passioné de développement personnel et de finance, je vous aide à définir vos objectifs et à prendre le contrôle de votre argent.<br>Dirigez le dans LA direction que vous aurez déterminé. Faites le travailler pour vous. Et commencez à vivre la vie que vous méritez !<br><br>Je met à disposition une partie de mes travaux gratuitement sur ce site et sur mes réseaux. Tout comme déjà de nombreux investisseurs, recevez gratuitement des conseils afin de devenir dès maintenant un meilleur investisseur !`"/>
      </v-col>
      <v-col cols="6" class="d-flex align-center justify-center">
        <v-img
            :src="require('@/assets/geoffrey.png')"
            class="full-height"
        ></v-img>
      </v-col>
    </v-row>
    <v-row class="full-width d-flex mt-10" align="center" justify="center">
      <v-btn class="text-none custom-button-style full-width large-button"
             color="#D9B436"
             rounded="2"
             size="x-large"
             variant="outlined"
             @click="goToNewsletter"
      >
        Je souhaite recevoir des conseils gratuit
      </v-btn>
    </v-row>
    <v-row class="d-flex justify-center mt-16 mr-16 ml-16">
      <video controls
             :src="require('@/assets/teaser.mp4')"
             class="mb-6"
             style="width: 100%;"
      ></video>
    </v-row>
    <v-row class="full-width d-flex mt-10" align="center" justify="center">
      <v-btn class="text-none custom-button-style full-width large-button"
             color="#D9B436"
             rounded="2"
             size="x-large"
             variant="outlined"
      >
        Je souhaite développer mon capital maintenant
      </v-btn>
    </v-row>
  </v-app>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import articlesApi from "@/services/articles"
import backgroundImageApi from "@/services/backgroundImages"

export default {
  name: 'HomeView',
  data() {
    return {
      products: [],
      backgroundImage: ""
    };
  },
  async mounted() {
    try {
      const resArticles = await articlesApi.getArticlesAndElements();
      const resBackgroundImage = await backgroundImageApi.getSpecificBackgroundImage(this.$route.name);

      this.products = resArticles.data.length > 0 ? resArticles.data : [];
      this.backgroundImage = resBackgroundImage.data.length > 0 ? resBackgroundImage.data[0] : "";
    } catch (error) {
      console.error("Error:", error);
    }
  },
  methods: {
    goToNewsletter() {
      this.$router.push('/newsletter');
    }
  }
}
</script>

<style scoped>
@import 'vue3-carousel/dist/carousel.css';
.carousel__item {
  height: 300px;
  width: 100%;
  background-color: #f0f0f0;
  color: black;
  font-size: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 3rem;
}
.flex-container {
  display: flex;
  align-items: center; /* Aligne les éléments verticalement au centre */
}

.large-button {
  width: 90%;
  font-size: 24px; /* Increase the font size */
}
</style>