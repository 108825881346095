import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import CarouselCard from 'vue-carousel-card'
import 'vue-carousel-card/styles/index.css'
import 'vuetify/styles'
import {createVuetify} from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import {aliases, mdi} from 'vuetify/iconsets/mdi'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import Carousel3d from 'vue-carousel-3d'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
import {
    faCartShopping,
    faStore,
    faChartSimple,
    faFile,
    faFilePdf,
    faUser,
    faList,
    faWallet,
    faSquareArrowUpRight,
    faComment,
    faImage,
    faEnvelope,
    faBox
} from '@fortawesome/free-solid-svg-icons'
import store from './store/store'

library.add(faSquareArrowUpRight, faImage, faComment, faCartShopping, faStore, faChartSimple, faFile, faFilePdf, faUser, faList, faWallet, faEnvelope, faBox)

import {initializeApp} from "firebase/app"
import AutoLogout from "./views/components/Logout/AutoLogout.vue";

const firebaseConfig = {
    apiKey: "AIzaSyAbn88xEfWp4SKSkJo7fV1NRFpSIk52Uio",
    authDomain: "rich-cbac7.firebaseapp.com",
    projectId: "rich-cbac7",
    storageBucket: "rich-cbac7.appspot.com",
    messagingSenderId: "916082948116",
    appId: "1:916082948116:web:d1aee718c3883976390f01",
    measurementId: "G-QS632DP5LT"
};

const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi
        },
    },
})



createApp(App).use(router).use(store).use(Carousel3d).use(vuetify).use(CarouselCard).component('font-awesome-icon', FontAwesomeIcon).component('auto-logout', AutoLogout).mount('#app')
initializeApp(firebaseConfig)

