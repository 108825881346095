<template>
  <v-container>
    <v-toolbar flat color="black">

      <v-toolbar-title>RICH</v-toolbar-title>
      <v-toolbar-items class="pr-16 mr-16">
        <div class="link-container d-flex justify-center align-center">
          <router-link to="/home"><v-btn text color="white">Accueil</v-btn></router-link>
          <router-link to="/articles"><v-btn text color="white">Articles</v-btn></router-link>
          <router-link to="/boutique" class="nav-link"><v-btn text color="white">Boutique</v-btn></router-link>
          <router-link to="/newsletter" class="nav-link"><v-btn text color="white">Newsletter</v-btn></router-link>
          <router-link to="/adminPage" class="nav-link" v-if="isUserLoggedIn && admin"><v-btn text color="white">Ma Page</v-btn></router-link>
        </div>
      </v-toolbar-items>
      <v-toolbar-items class="pr-8">
        <div class="link-container d-flex justify-center align-center">
          <router-link to="/userAccount" class="nav-link" v-if="isUserLoggedIn"><v-btn text color="white">User</v-btn></router-link>
          <router-link to="/" class="nav-link" v-if="isUserLoggedIn"><v-btn text color="white" @click="logout">Déconnexion</v-btn></router-link>
          <router-link to="/register" class="nav-link"  v-if="!isUserLoggedIn"><v-btn text color="white">Register</v-btn></router-link>
          <router-link to="/login" class="nav-link mr-4" v-if="!isUserLoggedIn"><v-btn text color="white" v-if="!isUserLoggedIn">Login</v-btn></router-link>
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item me-3">
              <router-link class="nav-link" :class="$route.name == 'Cart'? 'active':''" aria-current="page" :to="{ name: 'Cart' }">
                <i class="bi bi-cart3 h4"></i>
                <span v-if="$store.state.cart && $store.state.cart.length > 0" class="align-items-center justify-content-center translate-middle badge rounded-pill" style="background-color: #D9B436">
                  {{ $store.state.cart.length }}
                </span>
              </router-link>
            </li>
          </ul>
        </div>
      </v-toolbar-items>
    </v-toolbar>
  </v-container>
</template>

<script>
import {getAuth, signOut} from "firebase/auth"

export default {
  name: "Header",
  components: {},

  data() {
    return {
      isDefault: true,
      isUserLoggedIn : false,
      admin : false
    };
  },
  mounted() {
    this.isUserLoggedIn = this.$store.state.isUserLoggedIn
    if(this.$store.state.user){
      const test = this.$store.state.user.role
      if (test==="admin") {
        this.admin = true
      }else{
        this.admin = false
      }}
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.pageYOffset > 700) {
        this.isDefault = false;
      } else {
        this.isDefault = true;
      }
    },
    async logout(){
      try {
        const auth = getAuth()
        signOut(auth).then(async ()=>{
          await this.$store.dispatch('setToken', null)
          await this.$store.dispatch('setUser', null)
          window.location.href = '/'
        })
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style scoped>
.default {
  color : white !important;
  transition: background-color 0.3s ease;
}

.white {
  color: white !important;
  transition: background-color 0.3s ease;
}
</style>