import Api from '@/services/api'

export default {

    //-----------------------------------------------------//
    //                    Get
    //-----------------------------------------------------//

    getArticlesAndElements() {
        return Api().get('/getArticlesAndElements');
    },

    //-----------------------------------------------------//
    //                    Post
    //-----------------------------------------------------//

    postNewArticle(data) {
        return Api().post('/postNewArticle', data);
    },

    updateArticleElement(data) {
        return Api().put('/updateArticleElement', data);
    },

    //-----------------------------------------------------//
    //                    Delete
    //-----------------------------------------------------//

    deleteArticleElement(data) {
        return Api().put('/deleteArticleElement', data);
    }
}