import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {getAuth, onAuthStateChanged} from "firebase/auth";
import Failure from "../views/components/payment/failure.vue";
import Success from "../views/components/payment/success.vue";

const routes = [
  /*{
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },*/
  {
    path: '/',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue'),
    meta: {
      requiresGuest: true
    }
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/articles',
    name: 'ArticlesView',
    component: () => import('../views/ArticlesView.vue'),
  },
  {
    path: '/boutique',
    name: 'BoutiqueView',
    component: () => import('../views/BoutiqueView.vue'),
  },
  {
    path: '/newsletter',
    name: 'NewsletterView',
    component: () => import('../views/NewsletterView.vue'),
  },
  {
    path: '/about',
    name: 'AboutView',
    component: () => import('../views/AboutView.vue'),
  },
  {
    path: '/adminPage',
    name: 'AdminPageView',
    component: () => import('../views/AdminPageView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/articles/:id',
    name: 'ArticlesDetailsView',
    component: () => import('../views/ArticlesDetailsView.vue'),
  },
  {
    path: '/boutique/:id',
    name: 'BoutiqueDetailsView',
    component: () => import('../views/BoutiqueDetailsView.vue'),
  },
  {
    path: '/userAccount',
    name: 'UserAccountView',
    component: () => import('../views/UserAccountView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'RegisterView',
    component: () => import('../views/RegisterView.vue'),
  },
  /*{
    path: '/login',
    name: 'LoginView',
    component: () => import('../views/LoginView.vue'),
  },*/
  {
    path: '/payment',
    name: 'FeedView',
    component: () => import(/* webpackChunkName: "about" */ '../views/FeedView.vue'),
  },
  {
    path:'/cart',
    name:'Cart',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cart.vue'),
  },
  {
    path: '/commandsBuy/:id',
    name: 'commandsBuy',
    component: () => import('../views/CommandsBuy.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/failure",
    name: "Failure",
    component: Failure
  },
  {
    path: "/success",
    name: "Success",
    component: Success
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const getCurrentUser = () => {
    return new Promise ((resolve, reject)=>{
        const removeListener = onAuthStateChanged(
            getAuth(),
            (user)=>{
                removeListener();
                resolve(user)
            },
            reject
        );
    });
}

router.beforeEach(async (to, from, next) => {
  const requiresGuest = to.matched.some(record => record.meta.requiresGuest);
  const currentUser = await getCurrentUser();

    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (await getCurrentUser()) {
            next()
        } else {
            //next('/login')
          next('/')
        }
    } else if (requiresGuest) {
      if (currentUser) {
        next('/home'); // Redirect to home if already authenticated
      } else {
        next(); // Allow access to the guest route
      }
    } else {
      next(); // Continue for routes without specific auth requirements
    } 
});

export default router
