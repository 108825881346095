<template>
    <div>
      <h4>Please Try Again</h4>
    </div>
  </template>
  
  <script>
  export default {
    setup() {
      return {};
    }
  };
  </script>
  
  <style lang="scss" scoped></style>