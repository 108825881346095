<template>
  <v-app>
    <v-app-bar
        color="black"
        dark
        app
        flat
    >
    <auto-logout v-if="userLoggedIn"></auto-logout>

      <Header :is="headerComponent" v-if="userLoggedIn"/>
    </v-app-bar>

    <router-view/>
    <Footer />
  </v-app>
</template>

<script>
import Header from "@/layout/Header";
import Footer from "@/layout/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer
  },
  data() {
    return {
      headerComponent: Header,
      userLoggedIn : false
    };
  },
  async mounted() {
    await this.$store.commit('initialiseStore')
    if(this.$store.state.user){
      this.userLoggedIn = true
    }
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.transparent {
  background-color: transparent !important;
  transition: background-color 0.3s ease;
}

.black {
  background-color: black !important;
  transition: background-color 0.3s ease;
}
</style>