import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

export default new Vuex.Store({
    strict: true,
    plugins: [createPersistedState()],
    state: {
        hideConfigButton: false,
        isPinned: true,
        showConfig: false,
        sidebarType: "bg-white",
        isRTL: false,
        mcolor: "",
        darkMode: false,
        isNavFixed: false,
        isAbsolute: false,
        showNavs: true,
        showSidenav: true,
        showNavbar: true,
        showFooter: true,
        showMain: true,
        layout: "default",
        cart: [],
        cartTotal: 0,
        token: null,
        user: null,
        isUserLoggedIn: false,
        id: null,
        selectedProduct: null
    },
    mutations: {
        setToken(state, token) {
            state.token = token
            state.isUserLoggedIn = !!token;
        }, setUser(state, user) {
            if(user){
                state.user = user
                state.isUserLoggedIn = true
            }else{
                state.isUserLoggedIn = false
                state.user = null
            }
        },
        updateUser(state, updatedUser) {
            state.user = updatedUser;
        },
        toggleConfigurator(state) {
            state.showConfig = !state.showConfig;
        },
        navbarMinimize(state) {
            const sidenav_show = document.querySelector(".g-sidenav-show");

            if (sidenav_show.classList.contains("g-sidenav-hidden")) {
                sidenav_show.classList.remove("g-sidenav-hidden");
                sidenav_show.classList.add("g-sidenav-pinned");
                state.isPinned = true;
            } else {
                sidenav_show.classList.add("g-sidenav-hidden");
                sidenav_show.classList.remove("g-sidenav-pinned");
                state.isPinned = false;
            }
        },
        sidebarType(state, payload) {
            state.sidebarType = payload;
        },
        navbarFixed(state) {
            if (state.isNavFixed === false) {
                state.isNavFixed = true;
            } else {
                state.isNavFixed = false;
            }
        },
        async initialiseStore(state) {
            if(localStorage.getItem('cart')){
                state.cart = JSON.parse(localStorage.getItem('cart'))
            }
            if(localStorage.getItem('cartTotal')){
                state.cartTotal = parseFloat(localStorage.getItem('cartTotal'))
            }
            return true;
        },
        addRemoveCart(state, payload){
            payload.toAdd?
                state.cart.push(payload.product) :
                state.cart = state.cart.filter(function(obj){
                    return obj.id !== payload.product.id
                });
            state.cartTotal = state.cart.reduce((accumulator, object)=>{
                return parseFloat(accumulator) + parseFloat(object.actualPersonnalPrice * object.qty);
            },0);
            localStorage.setItem('cartTotal',JSON.stringify(state.cartTotal));
            localStorage.setItem('cart',JSON.stringify(state.cart));
        },
        updateCart(state,payload){
            state.cart.find(o => o.id === payload.product.id).qty = payload.product.qty;
            state.cartTotal = state.cart.reduce((accumulator, object)=>{
                return parseFloat(accumulator) + parseFloat(object.actualPersonnalPrice * object.qty);
            },0);
            localStorage.setItem('cartTotal',JSON.stringify(state.cartTotal));
            localStorage.setItem('cart',JSON.stringify(state.cart));
        },
        setProduct(state, product) {
            state.selectedProduct = product;
        }
    },
    actions: {
        setToken({commit}, token) {
            commit('setToken', token)
        }, setUser({commit}, user) {
            commit('setUser', user)
        },
        toggleSidebarColor({ commit }, payload) {
            commit("sidebarType", payload);
        },
        setProduct({ commit }, product) {
            commit("setProduct", product);
        }
    },
    methods: {
        setProductAction(product) {
            this.$store.dispatch('setProduct', product);
        }
    },
    getters: {
        getUserInfo: state => {
            return state.user;
        },
        selectedProduct: (state) => state.selectedProduct
    }
})